<template>
  <VContainer class="pa-0">
    <VTabs
      :value="value"
      color="black"
      :class="{'tabs-bar' : true,'tabs-bar--underline' : isToolbarUnderlined}"
      active-class="active-tab-background"
      background-color="transparent"
      data-test="tab-items"
      @change="onChange"
    >
      <VTabsSlider
        color="black"
      />
      <div
        v-for="tab in tabs"
        :key="tab.text"
        class="tabs-bar-tab"
        :class="{'tabs-bar-tab--underline' : !isToolbarUnderlined}"
      >
        <VTab
          :ripple="false"
          :href="`#${tab.value}`"
          class="justify-start px-0 tabs-bar-tab-hover--none tt-text-title-2
          fill-height align-start text-transform--none"
          :data-test="tab.dataTest || `tab-` + tab.value"
        >
          {{ tab.text }}
        </VTab>
      </div>
    </VTabs>
    <slot />
  </VContainer>
</template>

<script>
export default {
  name: 'TabsWrapper',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Object],
      required: true,
    },
    underline: {
      type: String,
      validator: (val) => ['tab', 'toolbar'].includes(val),
      default: () => 'tab',
    },
    tabs: {
      type: [Array],
      default: () => [],
    },
  },
  computed: {
    isToolbarUnderlined() {
      return this.underline === 'toolbar';
    },
  },
  methods: {
    onChange(val) {
      this.$emit('change', val);
    },
  },
};
</script>

<style scoped>

</style>
