<template>
  <VContainer>
    <VRow>
      <VCol>
        <TTBreadcrumbs
          :items="breadcrumbs"
          data-test-label="groups-breadcrumbs"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div
          class="tt-text-title-1"
          data-test="group-name"
        >
          {{ groupName }}
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div
          class="tt-text-body-2"
          data-test="group-description"
        >
          Общая адаптация для всех сотрудников, про компанию, документы, локацию, офис, акционеров и вообще всё тут
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VRow
          dense
          align="center"
        >
          <VCol cols="auto">
            <VChip data-test="group-chips-users">
              <VIcon
                left
                color="tt-light-mono-64"
              >
                fas fa-user-circle
              </VIcon>
              <span class="tt-light-mono-64--text tt-text-caption">
                {{ $tc('human',isEmptyState? 0 : 18) }}
              </span>
            </VChip>
          </VCol>
          <VCol cols="auto">
            <VChip data-test="group-chips-tracks">
              <VIcon
                left
                color="tt-light-mono-64"
              >
                fas fa-route
              </VIcon>
              <span class="tt-light-mono-64--text tt-text-caption">
                {{ $tc('tracks',isEmptyState? 0 :1) }}
              </span>
            </VChip>
          </VCol>
          <VCol>
            <VBtn
              color="warning"
              @click="handleClickEmptyStateToggle"
            >
              Empty State
            </VBtn>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VDivider />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TabsWrapper
          v-model="selectedTab"
          :tabs="tabs"
          underline="toolbar"
          data-test="tabs"
        >
          <VTabsItems v-model="selectedTab">
            <VTabItem
              v-for="tab in tabs"
              :key="tab.text"
              :data-test="`tab-content-` + tab.value"
              :transition="false"
              :value="tab.value"
            >
              <VContainer
                v-if="tab.value==='members'"
                class="px-0"
              >
                <VRow>
                  <VCol v-if="items.length">
                    <OTable
                      hide-default-footer
                      :headers="headers"
                      :items="items"
                      :search="search"
                      :custom-filter="handleFilter"
                    >
                      <template #top>
                        <VContainer class="px-0">
                          <VRow align="end">
                            <VCol cols="10">
                              <TTTextField
                                v-model="search"
                                large
                                data-test-label="members-list-search"
                                label="Поиск"
                                placeholder="ФИО или телефон"
                                clearable
                                append-icon="fal fa-search"
                                data-test="members-input-search"
                              />
                            </VCol>
                          </VRow>
                        </VContainer>
                      </template>
                      <template #item.name="{item}">
                        <VListItem class="px-0">
                          <VListItemAvatar
                            size="36"
                            data-test="member-avatar"
                          >
                            <VImg :src="item.img" />
                          </VListItemAvatar>
                          <VListItemContent class="py-0">
                            <VListItemTitle
                              class="tt-text-body-2"
                              data-test="member-name"
                            >
                              {{ item.name }}
                            </VListItemTitle>
                            <VListItemSubtitle
                              class="tt-text-body-2 tt-light-mono-46--text"
                              data-test="member-position"
                            >
                              {{ item.position }}
                            </VListItemSubtitle>
                          </VListItemContent>
                        </VListItem>
                      </template>
                    </OTable>
                  </VCol>
                  <VCol v-else>
                    <EmptySection
                      :show-arrow="false"
                      data-test="empty-section"
                      title="У этой группы нет участников"
                      text="Чтобы добавить участников в эту группу, настройте условия автодобавления."
                    />
                  </VCol>
                </VRow>
              </VContainer>
              <!-- TODO forceRefresh костыль для тестов, после тестов удалить             -->
              <VContainer
                v-if="tab.value==='conditions'"
                class="px-0 py-6"
              >
                <VRow
                  v-if="tree.length"
                >
                  <VCol>
                    <div class="tt-text-body-1">
                      Автоматически добавлять сотрудников, если:
                    </div>
                  </VCol>
                </VRow>
                <VRow dense>
                  <VCol cols="6">
                    <ConditionConstructor
                      v-if="!forceRefresh"
                      :tree-init.sync="tree"
                      :disabled="true"
                      :show-new-group-button="tree.length<=0"
                    />
                  </VCol>
                </VRow>
              </VContainer>
              <VContainer
                v-if="tab.value==='tracks'"
                class="px-0 py-6"
              >
                <template v-if="cardsInit.length">
                  <VRow>
                    <VCol
                      v-for="(item,index) in cardsInit"
                      :key="item.title"
                      cols="3"
                    >
                      <TrackCard
                        link
                        data-test="tracks-cards"
                        :to="{name : Names.R_TRACK,params : {id : index}}"
                        :image-src="item.imageSrc"
                        :title="item.title"
                        :text="item.text"
                      />
                    </VCol>
                  </VRow>
                </template>
                <template v-else>
                  <VRow>
                    <VCol>
                      <EmptySection
                        :show-arrow="false"
                        data-test="empty-section"
                        title="У этой группы нет назначенных треков"
                        text="Чтобы назначить трек на эту группу, зайдите в настройки трека во вкладку
                      «Назначения» и выберите нужную группу сотрудников."
                      >
                        <template #optional>
                          <TTBtn
                            text
                            :to="{name : Names.R_TRACKS}"
                            data-test-label="empty-section-button-track-edit"
                          >
                            Посмотреть треки
                          </TTBtn>
                        </template>
                      </EmptySection>
                    </VCol>
                  </VRow>
                </template>
              </VContainer>
            </VTabItem>
          </vtabsitems>
        </tabswrapper>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import TabsWrapper from '@/components/shared/TabsWrapper.vue';
import OTable from '@/components/shared/OTable.vue';
import TrackCard from '@/components/cards/TrackCard.vue';
import EmptySection from '@/components/shared/EmptySection.vue';
import ConditionConstructor from '@/components/consturctor/ConditionConstructor.vue';

export default {
  name: 'GroupId',
  components: {
    ConditionConstructor,
    EmptySection,
    TrackCard,
    OTable,
    TabsWrapper,
  },
  inject: ['Names'],
  data() {
    return {
      forceRefresh: false,
      selectedTab: '',
      tabs: [
        {
          text: 'Участники',
          value: 'members',
        },
        {
          text: 'Условия автодобавления',
          value: 'conditions',
        },
        {
          text: 'Треки',
          value: 'tracks',
        }],
      headers: [{
        text: 'Сотрудники',
        value: 'name',
        sortable: false,
      }],
      items: [...Array(5)]
        .map((_, index) => (
          {
            img: 'https://loremflickr.com/36/36',
            name: `Уэсли Мортимер Уэльс ${index}`,
            position: `Название должности ${index}`,
          })),
      search: '',
      cardsInit: [...Array(5)]
        .map((_, index) => (
          {
            id: index,
            title: `Card Title ${index}`,
            imageSrc: 'https://loremflickr.com/240/124',
            // eslint-disable-next-line max-len,vue/max-len
            // eslint-disable-next-line max-len,vue/max-len
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam, aliquid eligendi esse ex fugit illo impedit ipsa maiores nam nisi nostrum perspiciatis, reprehenderit, sed voluptate. Corporis illo ipsa reiciendis.\n',
          })),
      tree: [{
        groupIndex: 0,
        children: [{
          rule: 'is',
          option: 'designer',
        }, {
          rule: 'is',
          option: 'designer',
        }, {
          rule: 'is',
          option: 'designer',
        }],
      }, {
        groupIndex: 1,
        children: [{
          rule: 'is',
          option: 'designer',
        }, {
          rule: 'is',
          option: 'designer',
        }],
      }, {
        groupIndex: 2,
        children: [{
          rule: 'is',
          option: 'designer',
        }],
      }],
      isEmptyState: false,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          to: { name: this.Names.R_GROUPS },
          text: 'Группы',
        },
        {
          to: {
            name: this.Names.R_GROUP,
            params: { id: this.$route.params.id },
          },
          text: 'Название группы',
        },
      ];
    },
    groupName() {
      return 'Все сотрудники';
    },
  },
  mounted() {
    this.$root.$on('click:group-edit', this.showEditForm);
  },
  beforeDestroy() {
    this.$root.$off('click:group-edit', this.showEditForm);
  },
  methods: {
    // TODO Для служебного пользования, удалить после обвязки с апи
    handleClickEmptyStateToggle() {
      this.tree = [];
      this.cardsInit = [];
      this.items = [];
      this.forceRefresh = true;
      this.isEmptyState = true;
      setTimeout(() => { this.forceRefresh = false; }, 0);
    },
    showEditForm() {
      this.$router.push({
        name: this.Names.R_GROUP_MANAGEMENT,
        params: { id: this.$route.params.id },
      });
    },
    handleFilter(value, search, item) {
      return value != null
        && search != null
        && typeof value !== 'boolean'
        && item.name.toString()
          .toLocaleLowerCase()
          .indexOf(search.toLocaleLowerCase()) !== -1;
    },
  },
};
</script>

<style scoped>

</style>
