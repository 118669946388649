<template>
  <CardWrapper
    class="pa-0 fill-height"
    min-height="260"
    v-bind="$attrs"
    shadow="hover"
  >
    <VImg
      :aspect-ratio="16/9"
      height="124"
      :src="imageSrc"
      data-test="track-image"
    >
      <template #placeholder>
        <VSheet
          color="#f6f9fb"
          class="fill-height"
          data-test="track-button-image-placeholder"
        >
          <VRow
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <VCol cols="auto">
              <VIcon color="blue-grey lighten-3">
                fas fa-image
              </VIcon>
            </VCol>
          </VRow>
        </VSheet>
      </template>
      <VRow
        v-if="showMenu"
        class="pa-3"
        justify="end"
      >
        <VCol cols="auto">
          <MenuWrapper :menu-list="menuItems">
            <template #activator="{attrs,on}">
              <!--
              TODO FIX По неизвестной причине если не указать ripple="true" криво работают события, а именно
              при ripple="false" анимация клика срабатывает даже на самой карточке не смотря на указанный
              @click.native.prevent, если указать ripple="true", то все работает как ожидается
               -->
              <TTBtn
                small
                v-bind="attrs"
                color="rgba(255,255,255,.5)"
                min-width="28"
                class="pa-1"
                :ripple="true"
                data-test-label="track-button-show-menu"
                v-on="on"
                @click.native.prevent
              >
                <VIcon>
                  fal fa-ellipsis-h
                </VIcon>
              </TTBtn>
            </template>
          </MenuWrapper>
        </VCol>
      </VRow>
    </VImg>

    <div class="pa-4 tt-text-body-2">
      <VRow dense>
        <VCol
          cols="12"
        >
          <div data-test="track-title">
            {{ title }}
          </div>
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol
          cols="12"
        >
          <div
            class="tt-light-mono-46--text"
            data-test="track-description"
          >
            {{ textTruncated }}
          </div>
        </VCol>
      </VRow>
    </div>
  </CardWrapper>
</template>
<script>
import CardWrapper from '@/components/shared/CardWrapper.vue';
import MenuWrapper from '@/components/shared/MenuWrapper.vue';
import { stringTruncate } from '@/utils/stringHelpers';

export default {
  name: 'TrackCard',
  components: {
    MenuWrapper,
    CardWrapper,
  },
  props: {
    imageSrc: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },

    menuItems: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    textTruncated() {
      return stringTruncate(this.text, 140);
    },
    showMenu() {
      return this.menuItems.length;
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
