<template>
  <VDataTable
    v-bind="{...$props,...$attrs}"
    class="tt-data-table"
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
  >
    <slot
      v-for="(_, name) in $slots"
      :name="name"
    />
    <template
      v-for="(_, name) in $scopedSlots"
      #[name]="slotData"
    >
      <slot
        :name="name"
        v-bind="slotData"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  // eslint-disable-next-line vue/match-component-file-name
  name: 'OTable',
  props: {
    dataTest: {
      type: String,
      default: 'tt-data-table',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>

</style>
